.container {
    position: relative;
    max-width: 30rem;
    height: auto;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    font-size: 1.3em;
    font-family: sans-serif;
    width: 300px !important;
    background-color: gainsboro;
}
.container::before, .container::after {
    content: '';
    position: absolute;
    left: 40%;
    height: 0.8rem;
    width: 2px;
    margin-left: -1px;
    background-color: black;
}
.container::before {
    top: 0;
}
.container::after {
    bottom: 0;
}
.container .highlight {
    color: red;
    display: table-cell;
}
.container .leftSide {
    display: table-cell;
    width: 40%;
    text-align: right;
}
.container .rightSide {
    display: table-cell;
    width: 60%;
    text-align: left;
}
.container .timeoutBlock, .container .timeoutBlockHidden {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    transform-origin: 40%;
}
.container .timeoutBlockHidden {
    transform: scaleX(0);
}

.spritz {
  margin-top: 1rem;
  margin-bottom: 1rem;
}